import React from "react";
import styled from "styled-components";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Background from "../background";

const OcHeroWrapper = styled.div`
  display:flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2rem;
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;


  @media only screen and (min-width: 769px){
    align-items: flex-start;
  }

  @media only screen and (min-width: 501px) and (max-width: 768px){
    margin-bottom: 3rem;
  }

  /* @media only screen and (min-width:1920px){
    left:50%;
    position: relative;
    transform: translateX(-50%);

  } */
`;
const OrangeDesktopBackground = styled.div`
  background-color: var(--orange);
  position:absolute;
  top:0;
  right:0;
  width: 26%;
  height: 90%;
  z-index: 0;

  .orange_image{
    width: 100%;
  }

  @media only screen and (max-width: 768px){
    display:none;
  }

`;

const OcDesktopHeroRow = styled.div`
  position: relative;
  display:flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-top: 7rem;
  z-indeX: 0;
  margin-left: 4rem;
  margin-right: 2rem;

  .desktop_rowOne__text_container{
    width: 66%;
    /* @media only screen and (min-width: 1441px){
      padding-right: 5rem;
    } */

    @media only screen and (max-width: 1024px){
      width: 63%;
    }

    @media only screen and (max-width: 768px){
      width: 100%;
    }

  }

  h1{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 21px;
    text-align: center;
    letter-spacing: -0.005em;
    color: var(--orange);
    padding: 2rem 4rem 0 4rem;
    margin-top: 1rem;

    @media only screen and (max-width: 768px){
      padding: 0;
      margin-top: 0;
    }
  }

  h4{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 105.4%;
    text-align: center;
    letter-spacing: -0.005em;
    color: var(--blue);
    margin-bottom: 1rem;
    padding: 0 0.5rem;
    width: 100%;

    @media only screen and (min-width: 640px){
      font-size: 24px;
    }

    @media only screen and (min-width: 768px){
      font-size: 36px;
      /* width:70%; */
      text-align: left;
      padding: 0;
      margin-bottom: 2.5rem;
      /* padding-left: 5rem; */
    }


    
  }

  .top_bodyText, .bot_bodyText{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 152.9%;
    text-align: center;
    letter-spacing: -0.005em;
    margin-top: 0;
    margin-bottom: 0;
    padding: 0 1.3rem;
    width: 100%;

    @media only screen and (min-width: 768px){
      text-align: left;
      padding: 0;
    }
  }

  .top_bodyText{
    margin-bottom: 2rem;
  }

  @media only screen and (min-width: 769px){
    align-items: flex-start;
    padding-top: 10rem;
    
    h1{
      width:100%;
      text-align: left;
      padding: 0;
      margin-bottom: 1.5rem;
      /* padding-left: 5rem; */
    }

    .bot_bodyText{
      margin-bottom: 2rem;
    }
  }

  @media only screen and (min-width: 1025px){
    margin-left: 2rem;
    margin-right: 2rem;
  }

  @media only screen and (min-width: 1441px){
    margin-left: auto;
    margin-right: auto;
  }

  @media only screen and (min-width: 501px) and (max-width: 768px){
    margin-bottom: 0rem;
  }

  @media only screen and (max-width: 768px){
    padding-top: 3rem;
    margin-left: 2rem;
  }

  /* @media only screen and (min-width:1920px){
    left:50%;
    position: relative;
    transform: translateX(-50%);

  } */

`;

const OcHero = ({ content }) => {
  const { bodyTexts, orangeBackgroundRectangle } = content[0];
  return (
    <OcHeroWrapper>
      <OcDesktopHeroRow>
        <Background/>
        <div className="desktop_rowOne__text_container">
          <h1>{bodyTexts[0].text}</h1>
          <h4>{bodyTexts[1].text}</h4>
          <p className='top_bodyText'>{bodyTexts[2].text}</p>
          <p className='bot_bodyText'>{bodyTexts[3].text}</p>
        </div>
        <OrangeDesktopBackground>
          <GatsbyImage alt={orangeBackgroundRectangle.title} className="orange_image" image={getImage(orangeBackgroundRectangle.gatsbyImageData)}/>
        </OrangeDesktopBackground>
      </OcDesktopHeroRow>
    </OcHeroWrapper>
  );
};

export default OcHero;