import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";

const OcCompanyWrapper = styled.div`
  display:flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 5rem;
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;

  .text__container {
    @media only screen and (max-width: 1440px){
      margin-left: 2rem;
      margin-right: 2rem;
    }

    @media only screen and (max-width: 1024px){
      margin-left: 4rem;
    }

    @media only screen and (max-width: 1768px){
      margin-left: 2rem;
    }
  }

  .section_title{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 21px;
    text-align: left;
    letter-spacing: -0.005em;
    color:var(--orange);

    @media only screen and (max-width: 768px){
      text-align: center;
    }
  }

  h4{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 105.4%;
    /* or 32px */

    text-align: center;
    letter-spacing: -0.005em;
    color:var(--blue);
    margin-bottom: 2rem;

    @media only screen and (min-width: 640px){
      font-size: 24px;
      padding: 0 4.4rem;
    }

    @media only screen and (min-width: 769px){
      font-size: 36px;
      width:56%;
      text-align: left;
      padding: 0;
      /* padding-left: 5rem; */
    
    }
  }

  p{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 152.9%;
    /* or 28px */

    text-align: center;
    letter-spacing: -0.005em;
    margin-bottom: 1.5rem;
    /* padding: 0 1.6rem; */


    @media only screen and (min-width: 768px){
      /* padding: 0 5rem; */
      text-align: left;
    }
  }

  .bottom_bodyText{
    margin-bottom: 2rem;
  }

  .image_banner__container{
    margin-top:3rem;
    position:relative;
    width:100%;

    @media only screen and (min-width:769px){
      display:none;
    }
  }

  .button__container{
    width: 100%;
    text-align: left;

    @media only screen and (max-width: 768px){
      width: auto;
      margin-top: 3rem;
      margin-bottom: 3rem;
      margin-left: auto;
      margin-right: auto;
      text-align: center;
    }

    @media only screen and (max-width: 500px){
      margin: 0;
    }
  }

  .hire_button--desktop{
    @media only screen and (min-width: 1025px){
      width: 100%;
    }
  }

  .image_banner{
    width:100%;
  }

  .t_ffg_logo{
    position:absolute;
    width:auto;
    height:100%;
    top:15%;
    right:0;
  }

  .desktop_banner{
    margin-top: 3rem;

    @media only screen and (max-width: 768px){
      display:none;
    }
  }

  @media only screen and (min-width: 769px){
    align-items: flex-start;

    h2{
      width:100%;
      text-align: left;
      padding: 0;
      padding-left: 5rem;
    }

    /* .button__container{
      padding-left: 5rem;
    } */

  }

  /* @media only screen and (min-width:1920px){
    left:50%;
    transform: translateX(-50%);
    position: relative;
  } */
`;
const OcCompany = ({ content }) => {
  const { bodyTexts, imageAssets } = content[0];
  return (
    <OcCompanyWrapper>
      <div className="text__container">
        <p className="section_title">{bodyTexts[0].text}</p>
        <h4>{bodyTexts[1].text}</h4>
        <p>{bodyTexts[2].text}</p>
        <p className='bottom_bodyText'>{bodyTexts[3].text}</p>
        <div className="button__container">
          <Link to="/contact-us"><button className='hire_button hire_button--desktop'>Hire Us</button></Link>
        </div>
      </div>
      
      <div className="image_banner__container">
        <GatsbyImage alt={imageAssets[0].title} className="image_banner" image={getImage(imageAssets[0].gatsbyImageData)}/>
        <GatsbyImage alt={imageAssets[1].title} className="t_ffg_logo" image={getImage(imageAssets[1].gatsbyImageData)}/>
      </div>
      <GatsbyImage alt={imageAssets[2].title} className="desktop_banner" image={getImage(imageAssets[2].gatsbyImageData)}/>
    </OcCompanyWrapper>
  );
};

export default OcCompany;