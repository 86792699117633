import React from "react";
import { graphql } from "gatsby";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

import Layout from "../layouts";
import OcHero from "../components/ourCompany/ocHero";
import OcImageGallery from "../components/ourCompany/ocImageGallery";
import OcCompany from "../components/ourCompany/ocCompany";
import OcProcess from "../components/ourCompany/ocProcess";
import OcSteps from "../components/ourCompany/ocSteps";
import OcJoin from "../components/ourCompany/ocJoin";
import OcTeam from "../components/ourCompany/ocTeam";
import SEO from "../components/seo";

const OurCompany = ({ data }) => {
  const { nodes } = data.allContentfulOurCompanyPage;
  const hero = nodes.filter(node => node.sectionTitle === "01_Our_Company - Hero");
  const ig = nodes.filter(node => node.sectionTitle === "02_Our_Company - Image Gallery");
  const oc = nodes.filter(node => node.sectionTitle === "03_Our_Company - Our Company");
  const process = nodes.filter(node => node.sectionTitle === "04_Our_Company - Process");
  const steps = nodes.filter(node => node.sectionTitle === "05_Our_Company - Steps");
  const jt = nodes.filter(node => node.sectionTitle === "06_Our_Company - Join Team");
  const ot = nodes.filter(node => node.sectionTitle === "07_Our_Company - Our Team");

  return (
    <Layout>
      <SEO
        description="FFG is comprised of experts in marketing, design, and development. We utilize big-picture strategists and deep channel experts to help scale our clients."
        title="Our Company | Full Funnel Growth"
      />
      <OcHero content={hero}/>
      <OcImageGallery content={ig}/>
      <OcCompany content={oc}/>
      <OcProcess content={process}/>
      <OcSteps content={steps}/>
      <OcJoin content={jt}/>
      <OcTeam content={ot}/>
    </Layout>
  );
};

export default OurCompany;

export const query = graphql`
query OurCompanyQuery {
  allContentfulOurCompanyPage {
    nodes {
      sectionTitle
      bodyTexts {
        text
      }
      orangeBackgroundRectangle{
        title
        gatsbyImageData(
          quality: 100,
          placeholder: BLURRED
        )
      }
      cardComponents {
        references {
          cardTitle
          cardBodyText {
            text
          }
          cardImage {
            gatsbyImageData(
              quality: 100,
              placeholder: BLURRED
            )
          }
        }
      }
      imageAssets {
        title
        url
        gatsbyImageData(
          quality: 100,
          placeholder: BLURRED
        )
      }
    }
  }
}
`;