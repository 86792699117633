import React from "react";
import styled from "styled-components";
import OcEmployeeCard from "./ocEmployeeCard";
import { TiLocation } from "react-icons/ti";
import { Link } from "gatsby";

const OcTeamWrapper = styled.div`
  display:flex;
  flex-direction: column;
  align-items: center;
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;

  .text__container{
    display: flex;
    flex-direction: column;
    align-items: center;
    /* padding: 0 1.8rem; */
    margin-bottom: 3rem;
    
    @media only screen and (min-width: 769px){
      margin-bottom: 8rem;
    }
  }

  .team__headerText{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 129.9%;
    color: var(--blue);
    text-align: center;
    letter-spacing: -0.005em;
    margin: 0;
    margin-bottom: 2rem;
    
    @media only screen and (min-width: 640px){
      font-size: 24px;
    }

    @media only screen and (min-width: 768px){
      font-size: 36px;
    }
  }

  .team__bodyText{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 152.9%;
    text-align: center;
    margin: 0;
    letter-spacing: -0.005em;

    @media only screen and (min-width: 501px) and (max-width: 768px){
      padding: 0 4.4px;
      margin-bottom: 5rem;
    }

    @media only screen and (min-width: 769px){
      text-align: left;
    }

  }

  .employeeCard__container{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    place-items: center;
    width: 100%;
    margin-bottom: 5rem;
    margin-left: auto;
    margin-right: auto;

    @media only screen and (min-width: 501px) and (max-width: 768px){
      margin-bottom: 3rem;
    }

    @media only screen and (min-width: 1025px){
      grid-template-columns: repeat(3, 1fr);
    }

    @media only screen and (min-width: 1251px){
      grid-template-columns: repeat(4, 1fr);
    }
  }

  @media only screen and (min-width: 769px){
    align-items: flex-start;

    .text__container{
      /* padding: 0 5rem; */
      align-items: flex-start;
    }
  }

  @media only screen and (max-width: 1440px){
    margin-left: 2rem;
    margin-right: 2rem;
  }

  @media only screen and (max-width: 1024px){
    margin-left: 4rem;
  }

  @media only screen and (max-width: 768px){
    margin-left: 2rem;
  }
`;

const JoinTeam = styled.div`
  display:flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 5rem;
  position: relative;
  margin-left: auto;
  margin-right: auto;

  h4{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 129.9%;
    text-align: center;
    letter-spacing: -0.005em;
    color: var(--blue);
    margin-bottom: 3rem;
    padding: 0 1rem;

    @media only screen and (min-width: 640px){
      font-size: 24px;
    }

    @media only screen and (min-width: 768px){
      font-size: 36px;
    }

  }

  .button__container{    
    @media only screen and (min-width: 769px){
      .button__container{
        /* padding-left: 5rem; */
        width: 100%;
      }
    }
  }

  .open_positions{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    letter-spacing: -0.005em;
    padding: 0 1rem;
    max-width: 316px;
    width: 100%
  }

  @media only screen and (max-width: 768px){
    display: none;
  }
`;

const EmployeeTemplate = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -3rem;

  .blue_circle{
    display:flex;
    justify-content: center;
    align-items: center;
    width: 248px;
    height: 248px;
    aspect-ratio: 1;
    /* border-radius: 10rem;*/
    border-radius: 50%;
    margin-bottom: 1rem; 
    background-color: var(--blue);
  }

  .blue_circle__text{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 28px !important;
    line-height: 34px;
    text-align: center;
    letter-spacing: -0.005em;
    color: #ffffff;
    padding:0 2rem;
    
  }

  .orange_background_card{
    position: relative;
    top:-100px;
    z-index:-1;
    background-color: var(--orange);
    border-radius: 2rem;
    display: flex;
    flex-direction: column;
    /* justify-content: flex-end; */
    align-items: center;
    height:100%;
    padding-top: 8rem;
    width:57%;
  }

  .employee_name{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 12px !important;
    line-height: 15px;
    text-align: center;
    letter-spacing: -0.005em;
    color: #ffffff;
    padding: 0 2rem;
    padding-bottom: 1rem;
  }

  .location_icon{
    font-size:16px !important;
    color: #ffffff;
  }

  .location_text{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 10px !important;
    line-height: 12px;
    text-align: center;
    letter-spacing: -0.005em;
    color: #ffffff;
  }

  @media only screen and (max-width: 768px){
    display:none;
  }
`;

const OcTeam = ({ content }) => {
  const { bodyTexts, cardComponents } = content[0];

  return (
    <OcTeamWrapper>
      <div className="text__container">
        <h4 className="team__headerText">{bodyTexts[0].text}</h4>
        <p className='team__bodyText'>{bodyTexts[1].text}</p>
      </div>
      <div className="employeeCard__container">
        {cardComponents.references.map((card, index) => {
          return (
            <OcEmployeeCard card={card} key={index}/>
          );
        })}

        <EmployeeTemplate>
          <div className="blue_circle">
            <h4 className="blue_circle__text">This could be you!</h4>
          </div>
          <div className="orange_background_card">
            <h4 className='employee_name'>Apply to one of our open posititions!</h4>
            <TiLocation className='location_icon'/>
            <p className='location_text'>Anywhere</p>
          </div> 
        </EmployeeTemplate>
      </div>

      <JoinTeam>
        <h4>Interested in joining our team?</h4>
        <div className="button__container">
          <Link to="/careers#hiring"><button className='hire_button open_positions'>View Open Positions</button></Link>
        </div>
        
      </JoinTeam>
     
    </OcTeamWrapper>
  );
};

export default OcTeam;