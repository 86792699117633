import React, { Component } from "react";
import styled from "styled-components";
import Slider from "react-slick";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Link } from "gatsby";

const OcImageGalleryWrapper = styled.div`
  /* max-width: 1440px;
  display:flex;
  flex-direction: column;
  align-items: center; */
  width: 100%;
  margin-bottom: 5rem;
  position:relative;
  /* margin-left: auto;
  margin-right: auto; */
  .max_width__container{
    max-width: 1440px;
    display:flex;
    flex-direction: column;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    position: relative;
  }

  .card_image--desktop{
    @media only screen and (max-width: 1250px){
      transform: translateX(-25px);
    }
  }


  .image_max_width__container{
    max-width: 1560px;
    height: 650px;
    margin-left: auto;
    margin-right: auto;
    position: relative;

    & ._inner_container {
      max-width: 1440px;
      width: 100%;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      position: absolute;

      @media only screen and (max-width: 1440px){
        margin-left: 2rem;
      }

      @media only screen and (max-width: 1024px){
        margin-left: 4rem;
      }
      
      .icon_layout {
        margin-top: 3rem;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        align-items: flex-start;
        max-width: 600px;
        row-gap: 2rem;

        @media only screen and (max-width: 1250px){
          max-width: 500px;
        }
      }

      .learn_more__container{
        margin-top: 2rem;
        margin-bottom: 2rem;
      }

      .card__container{
        height: 225px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        @media only screen and (max-width: 1250px){
          height: 200px;
        }
      }

      .card__container p{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 123.4%;
        color: var(--blue);
        text-align: left;
        letter-spacing: -0.005em;

        @media only screen and (min-width: 640px){
          font-size: 24px;
        }
       
        @media only screen and (min-width: 768px){
          font-size: 36px;
        }
      }

    }

    @media only screen and (max-width: 768px){
      display: none;
    }
  }

  .image_gallery__blue_banner{
    background-color: var(--blue);
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    width:100%;

    .blue_banner_max {
      max-width: 1440px;
      margin-left: auto;
      margin-right: auto;
    }

    @media only screen and (min-width: 501px) and (max-width: 768px){
      padding-top: 2.5rem;
      padding-bottom: 2.5rem;
    }
  }

  .image_gallery__blue_banner h2{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 129.9%;
    /* or 45px */
    color: #ffffff;
    text-align: center;
    letter-spacing: -0.005em;
    margin:0;
    padding: 0 2.3rem;

    @media only screen and (min-width: 501px) and (max-width: 768px){
      padding: 0;
    }

    @media only screen and (min-width: 640px){
      font-size: 24px;
    }

    @media only screen and (min-width: 769px){
      width:50%;
      text-align:left;
      font-size: 36px;
      padding: 2rem 0rem;
    }

    @media only screen and (max-width: 1440px){
      margin-left: 2rem;
      margin-right: 2rem;
    }

    @media only screen and (max-width: 1024px){
      margin-left: 4rem;
    }

    @media only screen and (max-width: 768px){
      margin-left: 2rem;
    }
  }

  .slick-next{
    margin-top: 1rem;
    top:100%;
    right:30%;
    border-radius: 3rem;

    @media only screen and (min-width: 501px) and (max-width: 768px){
      margin-top: 2.5rem;
      right: 45%;
    }

    &::before{
      content:""
    }
  }

  .custom-right-arrow{
    border-bottom: 10px solid transparent;
    border-left: 10px solid gray;
    border-top: 10px solid transparent; 
    height: 0;
    width: 0;

    &:hover{
      border-left: 10px solid var(--orange);
    }
  }

  .custom-left-arrow{
    border-bottom: 10px solid transparent;
    border-right: 10px solid gray;
    border-top: 10px solid transparent; 
    height: 0;
    width: 0;

    &:hover{
      border-right: 10px solid var(--orange);
    }
  }

  .slick-prev{
    margin-top: 1rem;
    top:100%;
    left:30%;
    border-radius: 3rem;

    @media only screen and (min-width: 501px) and (max-width: 768px){
      margin-top: 2.5rem;
      left: 45%;
    }

    &::before{
      content:""
    }
  }

  .bg__image{
    border-radius: 50%;
    margin-bottom: 1rem;
  }

  .bg__border{
    border-radius: 50%;
  }

  .background_desktop{
    aspect-ratio: 1/1;
    border-radius: 15rem;

    @media only screen and (max-width: 768px){
      display:none;
    }
  }

  .background_desktop--image1{
    position:absolute;
    width:36%;
    top:27%;
    border-radius: 25rem;
    right: -50px;
    max-width: 515px;
  }

  .background_desktop--image2{
    position:absolute;
    top:-150px;
    width:20%;
    left:48%;
    max-width: 288px;

    @media only screen and (max-width: 1024px){
      left: 60%;
      top:-130px;
    }
  }

  .background_desktop--image3{
    position:absolute;
    top:30%;
    width:14%;
    left:50%;
    max-width: 198px;

    @media only screen and (max-width: 1024px){
      left:53%;
    }
  }

  .background_desktop--image4{
    position:absolute;
    top:-380px;
    width:25%;
    right:5%;
    max-width: 362px;
  }

  @media only screen and (max-width: 768px){
    margin-bottom: 3rem;
  }

  /* @media only screen and (min-width:1920px){
    left:50%;
    position: relative;
    transform: translateX(-50%);

  } */
`;

const OcImageGalleryInnerWrapper = styled.div`
  width:100%;
  margin-top: 3rem;
  .logo_container img{
    position: relative;
    left:50%;
    transform: translateX(-50%);
    /* height:50px;
    width:200px; */
    object-fit: contain;
    border-radius: 10rem;
  }
  
  @media only screen and (min-width:769px){
    display:none;
  }
`;

const Learn = styled.div`
  margin-top:5rem;
  position:relative;
  width:100%;
  height: 100%;
  display:flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;

  .learn__top{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    place-items: center;
    width:100%;
    margin-top:3rem;
    /* height: 75%; */

    @media only screen and (max-width: 500px){
      margin-top: 0rem;
    }
    

    @media only screen and (min-width: 501px) and (max-width: 768px){
      width:auto;
      row-gap: 1rem;
      column-gap: 6rem;
    }

    @media only screen and (min-width: 769px){
      width:50%;
      /* grid-template-columns: repeat(2, 200px); */
      column-gap: 1rem;
    }

    @media only screen and (min-width: 1025px){
      width:50%;
      /* grid-template-columns: repeat(2, 250px); */
      column-gap: 1rem;
    }

    @media only screen and (min-width: 1251px){
      width:50%;
      /* grid-template-columns: repeat(2, 325px); */
      column-gap: 1rem;
    }
  }

  .learn__bot{
    display:flex;
    align-items: center;
    justify-content: center;
    width:100%;
    /* height: 25%; */
    margin-top: 1rem;

    @media only screen and (min-width: 501px) and (max-width: 768px){
      margin-top: 3rem;
      margin-bottom: 1rem;  
    }

    @media only screen and (min-width: 769px){
      display: none;
      padding-left: 5rem;
      margin-top: 2rem;
      width: 100%;
    }
  }

  .learn_more_button{

    @media only screen and (min-width: 1025px){
      width: 100%;
    }

    @media only screen and (min-width: 769px){
      position: relative;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .background_image{
    position:absolute;
    width:100%;
    height:100%;
    top:0;
    left:0;
    z-index:-1;
  }

  .background_image--mobile{
    @media only screen and (min-width:769px){
      display:none;
    }
  }

  .background_image--desktop{
    @media only screen and (max-width:768px){
      display:none;
    }
  }

  .card__container{
    display:flex;
    flex-direction: column;
    align-items: center;
    width:100%;
    justify-content: flex-end;

    /* @media only screen and (min-width:501px){
      align-items: flex-start;
    } */

  }

  .card_image{
    @media only screen and (min-width: 1025px){
      width: 145px;
      height: 145px;
    }

    @media only screen and (max-width: 500px){
      max-width: 85px;
      max-height: 85px;
    }
  }

  .card__container p{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 123.4%;
    color: var(--blue);
    text-align: center;
    letter-spacing: -0.005em;

    @media only screen and (min-width: 1251px){
      font-size: 36px;
    }
  }

  @media only screen and (min-width:769px){
    margin-top:0;
    align-items: flex-start;
    height: 590px;
    padding: 0;
    display: none;
  }

  @media only screen and (min-width: 501px) and (max-width: 768px){
    /* height: 590px; */
  }
`;

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      alt="nextArrow"
      className={`${className} custom-right-arrow`}
      onClick={onClick}
      style={{ ...style }}
    >
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      alt="prevArrow"
      className={`${className} custom-left-arrow`}
      onClick={onClick}
      style={{ ...style }}
    >
    </div>
  );
}

export default class OcImageGallery extends Component {
  render() {
    const settings = {
      autoplay: true,
      autoplaySpeed: 4000,
      dots: false,
      infinite: true,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
      slidesToScroll: 1,
      slidesToShow: 1,
      speed: 1000,
    };
    const { imageAssets, bodyTexts, cardComponents } = this.props.content[0];
    return (
 
      <OcImageGalleryWrapper>
        <div className="image_gallery__blue_banner">
          <div className="blue_banner_max">
            <h2>{bodyTexts[0].text}</h2>
          </div>
        </div>
        <div className="image_max_width__container"> 
          <GatsbyImage alt={imageAssets[5].title} image={getImage(imageAssets[5].gatsbyImageData)} style={{ width: "100%", height: "100%" }}/>
          <div className="_inner_container">
           
            <div className="icon_layout">
              {cardComponents.references.map((card, index) => {
                return (
                  <div className="card__container" key={index}>
                    <div className="card_image__container">
                      <GatsbyImage alt={card.cardTitle} className="card_image--desktop" image={getImage(card.cardImage.gatsbyImageData)}/>
                    </div>
                    <p>{card.cardTitle}</p>
                  </div>
                );
              })}

            </div>
            <div className="learn_more__container">
              <Link to="/our-services"><button className='hire_button learn_more_button'>Learn More</button></Link>
            </div>
          </div>
          <GatsbyImage alt={imageAssets[6].title} className="background_desktop background_desktop--image1 bg__border" image={getImage(imageAssets[6].gatsbyImageData)} imgClassName='bg__image'/>
          <GatsbyImage alt={imageAssets[1].title} className="background_desktop background_desktop--image2 bg__border" image={getImage(imageAssets[1].gatsbyImageData)} imgClassName='bg__image'/>
          <GatsbyImage alt={imageAssets[3].title} className="background_desktop background_desktop--image3 bg__border" image={getImage(imageAssets[3].gatsbyImageData)} imgClassName='bg__image'/>
          <GatsbyImage alt={imageAssets[7].title} className="background_desktop background_desktop--image4 bg__border" image={getImage(imageAssets[7].gatsbyImageData)} imgClassName='bg__image'/>
        </div>

        <div className="max_width__container">        
          <OcImageGalleryInnerWrapper>
            <Slider {...settings}>
              {imageAssets.map((image, index) => {
                return index <= 3 && (
                  <div className="logo_container" key={index}>
                    <img alt={image.title} src={image.url}/>
                  </div>
                );
              })}
            </Slider>
          </OcImageGalleryInnerWrapper>
          <Learn>
            <div className="learn__top">
              {cardComponents.references.map((card, index) => {
                return (
                  <div className="card__container" key={index}>
                    <div className="card_image__container">
                      <GatsbyImage alt={card.cardTitle} className="card_image" image={getImage(card.cardImage.gatsbyImageData)}/>
                    </div>
                    <p>{card.cardTitle}</p>
                  </div>
                );
              })}
            </div>
            <div className="learn__bot">
              <Link to="/our-services"><button className='hire_button learn_more_button'>Learn More</button></Link>
            </div>
            
            <GatsbyImage alt={imageAssets[4].title} className="background_image background_image--mobile" image={getImage(imageAssets[4].gatsbyImageData)}/>
            <GatsbyImage alt={imageAssets[5].title} className="background_image background_image--desktop" image={getImage(imageAssets[5].gatsbyImageData)}/>
          </Learn>
        </div>
        
      </OcImageGalleryWrapper>
      
    );
  }
}
