import React from "react";
import styled from "styled-components";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { TiLocation } from "react-icons/ti";

const OcEmployeeMobileCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height:100%;
  width:100%;
  margin-bottom: 1rem;
  padding: 0.5rem;

  .employee_image{
    /* width: 50%; */
    border-radius: 50%;
    margin-bottom: 1rem;
  }

  .employee_border{
    border-radius: 50%;
  }

  .employee_name{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 16px !important;
    line-height: 24px;
    text-align: center;
    letter-spacing: -0.005em;
    color: var(--blue);
    margin:0;
  }

  .orange_divider{
    width: 50%;
    height: 10px;
    border-top: 3px solid var(--orange);
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  .employee_title{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 14px !important;
    line-height: 20px;
    text-align: center;
    letter-spacing: -0.005em;
    color: var(--blue);
    margin: 0;
    padding: 0 1.5rem;
  }

  @media only screen and (min-width: 501px) and (max-width: 768px){
    margin-bottom: 3rem;
  }

  @media only screen and (min-width: 769px){
    display: none;
  }
`;

const OcEmployeeDesktopCardWrapper = styled.div`
  margin-top: -3rem;
  position: relative;
  display:flex;
  flex-direction: column;
  align-items: center;

  .desktop_employee_image{
    margin-bottom: 1rem;
    border-radius: 10rem;
  }

  .employee_image{
    /* width: 50%; */
    border-radius: 50%;
    margin-bottom: 1rem;
  }

  .employee_border{
    border-radius: 50%;
  }

  .employee_name{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 16px !important;
    line-height: 20px;
    text-align: center;
    letter-spacing: -0.005em;
    color:#ffffff;
    margin-bottom: 0.5rem;
  }

  .orange_background_card{
    position: relative;
    top:-100px;
    z-index:-1;
    background-color: var(--orange);
    border-radius: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    padding-top: 7rem;
    height:100%;
    width:75%;
  }

  .white_divider{
    width: 25%;
    height: 10px;
    border-top: 3px solid #ffffff;
    margin-top: 0;
    margin-bottom: 0;
  }

  .employee_title{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 12px !important;
    line-height: 15px;
    text-align: center;
    letter-spacing: -0.005em;
    color:#ffffff;
    margin-top: 0.5rem;
    padding: 0 1rem;
  }

  .location_icon{
    font-size:16px !important;
    color: #ffffff;

  }

  .location_text{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 10px !important;
    line-height: 12px;
    text-align: center;
    letter-spacing: -0.005em;
    color: #ffffff;
  }

  @media only screen and (max-width: 768px){
    display:none;
  }
`;

const OcEmployeeCard = ({ card }) => {
  const { cardTitle, cardBodyText, cardImage } = card;
  return (
    <>
      <OcEmployeeDesktopCardWrapper>
        <GatsbyImage alt={cardTitle} className="employee_border" image={getImage(cardImage.gatsbyImageData)} imgClassName='employee_image'/>
        <div className="orange_background_card">
          {/* name */}
          <h4 className='employee_name'>{cardTitle}</h4>
          {/* orange divider */}
          <div className='white_divider'></div>
          {/* title */}
          <p className='employee_title'>{cardBodyText[0].text}</p>
          <TiLocation className='location_icon'/>
          <p className='location_text'>{cardBodyText[1].text}</p>
        </div> 
      </OcEmployeeDesktopCardWrapper>

      <OcEmployeeMobileCardWrapper>
        {/* image */}
        <GatsbyImage alt={cardTitle} className="employee_border" image={getImage(cardImage.gatsbyImageData)} imgClassName='employee_image'/>
        {/* name */}
        <h4 className='employee_name'>{cardTitle}</h4>
        {/* orange divider */}
        <div className='orange_divider'></div>
        {/* title */}
        <p className='employee_title'>{cardBodyText[0].text}</p>
      </OcEmployeeMobileCardWrapper>
    </>
    
  );
};

export default OcEmployeeCard;