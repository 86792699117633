import React from "react";
import styled from "styled-components";

const OcStepsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;

  h4{
    color: var(--blue);
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 129.9%;
    /* or 39px */

    text-align: center;
    letter-spacing: -0.005em;
    margin-bottom: 4rem;

    @media only screen and (min-width: 640px){
      font-size: 24px;
      margin-bottom: 6rem;
    }

    @media only screen and (min-width: 768px){
      font-size: 36px;
      text-align: left;
    }
  }

  .steps__container{
    width: 90%;

    /* @media only screen and (min-width:769px){
      padding-left: 5rem;
    } */

  }

  .step__container{
    display:flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    margin-bottom: 5rem;

    @media only screen and (min-width: 501px) and (max-width: 768px){
      margin-bottom: 7rem;
    }
  }

  .step__number{
    position: absolute;
    top:0%;
    left:50%;
    background-color: #ffffff;
    padding: 0;
    transform: translate(-50%,-75%);
    border-radius: 10rem;

    @media only screen and (min-width: 769px){
      left:0%;
      top:50%;
      transform: translate(-5%,-50%);
      
    }
  }

  .step__number p{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 129.9%;
    text-align: center;
    letter-spacing: -0.005em;
    color: var(--blue);
    border-radius: 10rem;
    border: 5px solid var(--orange);
    padding: 0.5rem 1rem;
    margin:0;

    @media only screen and (min-width: 640px){
      font-size: 20px;
      padding: 1rem 1.5rem;
    }

    @media only screen and (min-width: 768px){
      font-size: 24px;
      border: 10px solid var(--orange);
      padding: 2rem 2.3rem;
    }
  }

  .step__text{
     @media only screen and (min-width: 769px){
      width:100%;
     }
  }

  .step__text p{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 152.9%;
    /* or 32px */

    text-align: center;
    letter-spacing: -0.005em;
    color: var(--blue);
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-right: 2rem;
    padding-left: 2rem;
    border: 5px solid var(--orange);
    border-radius: 3rem;

    @media only screen and (min-width: 501px) and (max-width: 768px){
      padding: 0 6.2rem;
      padding-bottom: 2rem;
      padding-top: 1rem;
    }

    @media only screen and (min-width: 769px){
      margin-top: 1rem;
      margin-bottom: 1rem;
      width:100%;
      text-align: left;
      padding-left: 8rem;
    }
  }

  @media only screen and (min-width:769px){
    align-items: flex-start;
    padding: 0;
  }

  @media only screen and (max-width: 1440px){
    margin-left: 2rem;
    margin-right: 2rem;
  }

  @media only screen and (max-width: 1024px){
    margin-left: 4rem;
  }

  @media only screen and (max-width: 768px){
    margin-left: 2rem;
  }
`;

const OcSteps = ({ content }) => {
  const { bodyTexts, cardComponents } = content[0];
  return (
    <OcStepsWrapper>
      <h4>{bodyTexts[0].text}</h4>
      <div className="steps__container">
        {cardComponents.references.map((card, index) => {
          return (
            <div className="step__container" key={index}>
              <div className="step__number">
                <p>{card.cardBodyText[0].text}</p>
              </div>
              <div className="step__text">
                <p>{card.cardBodyText[1].text}</p>
              </div>
            </div>
          );
        })}
      </div>
    </OcStepsWrapper>
  );
};

export default OcSteps;